import WebrtcCommon from "./webrtc-common"
import store from "@/store"
import { WEBRTC_CONNECTION_TYPE_RECEIVER } from "./constants"
import _ from "lodash"
import { emptyHtmlElm } from "@/common/general.service"

export default class WebrtcReceiver extends WebrtcCommon {
  previewedConn = null
  intervalPreviewRecconect = null
  intervalInitialisedPR = null
  reconectionPreview = true

  constructor(props) {
    super(props)
    if (Object.prototype.hasOwnProperty.call(props, "reconectionPreview")) {
      this.reconectionPreview = props.reconectionPreview
      delete props.reconectionPreview
    }
    this.type = WEBRTC_CONNECTION_TYPE_RECEIVER
  }

  get roomMembers() {
    return store.getters.getRoomMembersWsConn
  }

  get roomMembersSenders() {
    return store.getters.signalRoomMembersSenders
  }

  get audioGain() {
    return store.getters.getRemoteStreamAudioGain
  }

  get remoteIsMuted() {
    return store.getters.getRemoteStreamIsMuted
  }

  get webengineStream() {
    return store.getters.getActiveWebEngine.remote.stream
  }

  stopAllStreams() {
    return new Promise((resolve) => {
      this.closeRemotesPreview().then(() => {
        resolve()
      })
    })
  }

  closeRemotesPreview() {
    let self = this
    return new Promise((resolve, reject) => {
      self
        .closeAllPeerConnections()
        .then(() => {
          // self.disconnect();
          var remoteVideo = document.getElementById("received_video")
          if (remoteVideo) {
            // Stop the videos
            if (remoteVideo.srcObject) {
              remoteVideo.srcObject.getTracks().forEach((track) => track.stop())
              try {
                remoteVideo.srcObject = null
              } catch (err) {
                // catch set srcObject to null
              }
            }
            remoteVideo.src = null
          }
          self.remoteStream = null
          let container = self.getStreamContainer()
          emptyHtmlElm(container)
          store.dispatch("saveIsPreviewRemoteStreamStarted", false).then(() => {
            setTimeout(() => {
              resolve()
            }, 20)
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * get numer of audio channels of sender, for use it for offer to receive stream (number of tranceivers to create)
   * @param selectedStream
   * @return Integer
   */
  getNrAudioChannels(selectedStream) {
    let nrAudioCh = 2 // default numbers of channels
    if (selectedStream) {
      let sender = this.roomMembersSenders.find(
        (s) => s.id === selectedStream.id
      )
      if (
        sender &&
        Object.prototype.hasOwnProperty.call(sender, "AudChannals")
      ) {
        nrAudioCh = parseInt(sender.AudChannals)
      }
    }
    return nrAudioCh
  }

  /* eslint-disable-next-line */
  makeOfferPreview(selectedStream, sender) {
    let self = this
    self.previewedConn = selectedStream
    console.log("selectedStream", selectedStream)
    self.getOrCreateConnection().then(function (conn) {
      //if (self.props.multicastType !== "janus") {
      let senderNickName = selectedStream.nickname || selectedStream.nickName
      conn.emit(
        "setinfo",
        JSON.stringify({
          peer: senderNickName,
          object: senderNickName,
        })
      )
      setTimeout(() => {
        self
          .getOrCreatePeerConnection(conn.id, "remote", selectedStream.id)
          .then((remotePeer) => {
            if (remotePeer.offer) {
              let nrAudioCh = self.getNrAudioChannels(selectedStream)
              let nrTranceivers = nrAudioCh / 2
              for (let i = 0; i < nrTranceivers; i++) {
                remotePeer.pc.addTransceiver("audio", {
                  direction: "recvonly",
                })
              }

              // create an offer
              remotePeer.pc
                .createOffer({
                  //iceRestart: true,
                  offerToReceiveAudio: true,
                  offerToReceiveVideo: true,
                })
                .then(function (offer) {
                  if (self.props.multicastType === "janus") {
                    let msgJanusListner = {
                      to: selectedStream.id,
                      toName: selectedStream.nickName,
                      type: "januslistner",
                    }
                    conn.emit("message", JSON.stringify(msgJanusListner))
                  }
                  remotePeer.pc.setLocalDescription(offer).then(() => {
                    var msg_send = {
                      audBitrate: "128000",
                      audEncoder: "opus",
                      from: "", // conn.id,
                      payload: offer,
                      prefix: "webkit",
                      roomType: "Video",
                      sid: "",
                      to:
                        self.props.multicastType === "janus"
                          ? "janus"
                          : selectedStream.id,
                      type: "offer",
                      vidBitrate: "5500000",
                      vidEncoder: self.getVideoEncoder(),
                      token: self.tokenVal,
                      loginHash:
                        "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
                    }
                    console.log("22222222", msg_send)
                    conn.emit("message", JSON.stringify(msg_send))

                    self.initialisedPreviewReconnect = true
                    if (self.intervalInitialisedPR) {
                      clearTimeout(self.intervalInitialisedPR)
                      self.intervalInitialisedPR = null
                    }
                    self.intervalInitialisedPR = setTimeout(() => {
                      self.initialisedPreviewReconnect = false
                    }, 12000)
                  })
                })
                .catch((err) => {
                  console.error("On remotePeer.offer", err, remotePeer)
                })
              self.handleOnconnectionstatechange(remotePeer)
            }
          })
      }, 400)
      //}
    })
  }

  handleOnconnectionstatechange(remotePeer) {
    let self = this
    /* eslint-disable-next-line */
    remotePeer.pc.onconnectionstatechange = function (event) {
      console.log(
        "onconnectionstatechange--receiver",
        remotePeer.pc.connectionState
      )
      switch (remotePeer.pc.connectionState) {
        case "connected":
          // The connection has become fully connected
          self.removeReconnectPreview()
          break
        case "disconnected":
          console.log(
            "onconnectionstatechange--disconnected",
            this.reconectionPreview
          )
          if (this.reconectionPreview) self.reconnectPreview()
          self.emit("state-disconnected", remotePeer)
          break
        case "failed":
          console.log("onconnectionstatechange--failed", remotePeer)
          self.emit("state-failed")
          // One or more transports has terminated unexpectedly or in an error
          break
        case "closed":
          console.log("onconnectionstatechange--closed", remotePeer)
          // The connection has been closed
          break
      }
    }
  }

  getPeerByMsg(msg) {
    let self = this
    return new Promise((resolve) => {
      if (_.get(self.peers, [msg.from, "remote"])) {
        resolve(_.get(self.peers, [msg.from, "remote"]))
      } else if (_.get(self.peers, [msg.to, "remote"])) {
        resolve(_.get(self.peers, [msg.to, "remote"]))
      } else {
        self.getOrCreatePeerConnection(msg.to, "remote").then((peer) => {
          resolve(peer)
        })
      }
    })
  }

  reconnectPreview() {
    let self = this
    self.doEmitReconnectPreview()
    self.intervalPreviewRecconect = setInterval(function () {
      if (!self.initialisedPreviewReconnect) {
        self.doEmitReconnectPreview()
      }
    }, 2000)
  }

  doEmitReconnectPreview() {
    let self = this
    let findedOldPreviewer = _.find(self.roomMembers.senders, {
      nickName: self.previewedConn.nickName,
    })
    if (findedOldPreviewer) {
      self.emit("reconnect-preview", findedOldPreviewer)
    }
  }

  removeReconnectPreview() {
    let self = this
    self.initialisedPreviewReconnect = false
    if (self.intervalPreviewRecconect) {
      clearInterval(self.intervalPreviewRecconect)
      self.intervalPreviewRecconect = null
    }
  }

  updateStreamVolume() {
    let findedVideoElm = this.getStreamElm("remote")
    findedVideoElm.volume = parseFloat(this.audioGain)
    if (this.audioGain > 0 && this.remoteIsMuted) {
      this.$store.dispatch("saveMutedAudioRemoteStreamAction", false)
    }
  }

  getConnInfoData() {
    let data = super.getConnInfoData()
    //let nickName = this.getNickName()
    if (this.props.multicastType === "janus") {
      // data.peerEx = nickName;
      // data.peer = nickName;
      //data.object = nickName;
    }
    return data
  }
}
