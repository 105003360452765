import adapter from "webrtc-adapter"

export default class Sdp {
  static updateBandwidthRestriction(sdp, bandwidth) {
    let modifier = "AS"
    if (adapter.browserDetails.browser === "firefox") {
      bandwidth = (bandwidth >>> 0) * 1000
      modifier = "TIAS"
    }
    if (sdp.indexOf("b=" + modifier + ":") === -1) {
      // insert b= after c= line.
      sdp = sdp.replace(
        /c=IN (.*)\r\n/,
        "c=IN $1\r\nb=" + modifier + ":" + bandwidth + "\r\n"
      )
    } else {
      sdp = sdp.replace(
        new RegExp("b=" + modifier + ":(.*?)\r\n"),
        "b=" + modifier + ":" + bandwidth + "\r\n"
      )
    }
    return sdp
  }

  static removeBandwidthRestriction(sdp) {
    return sdp.replace(/b=AS:.*\r\n/, "").replace(/b=TIAS:.*\r\n/, "")
  }

  static setMediaBitrates(sdp) {
    //return this.setMediaBitrate(sdp, "video", 100);
    return this.setMediaBitrate(
      this.setMediaBitrate(sdp, "video", 100),
      "audio",
      20
    )
  }

  static setMediaBitrate(sdp, media, bitrate) {
    let modifier = "AS"
    if (adapter.browserDetails.browser === "firefox") {
      modifier = "TIAS"
      bitrate = (bitrate >>> 0) * 1000
    }
    var lines = sdp.split("\r\n")
    var line = -1
    for (var i = 0; i < lines.length; i++) {
      if (lines[i].indexOf("m=" + media) === 0) {
        line = i
        break
      }
    }
    if (line === -1) {
      console.debug("Could not find the m line for", media)
      return sdp
    }
    console.debug("Found the m line for", media, "at line", line)

    // Pass the m line
    line++

    // Skip i and c lines
    while (lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
      line++
    }

    // If we're on a b line, replace it
    if (lines[line].indexOf("b") === 0) {
      console.debug("Replaced b line at line", line)
      lines[line] = "b=" + modifier + ":" + bitrate
      return lines.join("\r\n")
    }

    // Add a new b line
    console.debug("Adding new b line before line", line)
    var newLines = lines.slice(0, line)
    newLines.push("b=" + modifier + ":" + bitrate)
    // newLines.push("a=fmtp:100 x-google-min-bitrate=50")
    newLines = newLines.concat(lines.slice(line, lines.length))
    return newLines.join("\r\n")
  }

  static addStereoMaxAverageBitrate(sdp, maxaveragebitrate) {
    return sdp.replace(
      "useinbandfec=1",
      `useinbandfec=1;stereo=1;maxaveragebitrate=${maxaveragebitrate}`
    )
  }
}
