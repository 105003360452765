import _ from "lodash"
import { mapGetters } from "vuex"
import WebrtcReceiverStreams from "@/common/webrtc/streams/webrtc-receiver-streams"

export default {
  data() {
    return {
      search: "",
      dialogHistory: false,
      errorSignalConnection: false,
      receivedActiveData: false,
      receivedInactiveData: false,
    }
  },

  watch: {
    streamsActiveSignalServerUrl: function (newVal) {
      if (newVal && this.streamsPeerReceiver) {
        this.reloadWebrtcConns()
        // @todo maybe later to do auto restart stream on change signal url or signal room
        // let pr1 = self.streamsPeerReceiver.onSignalUrlChangeCommon(newVal, oldVal)
        // Promise.all([pr1]).then(() => {
        //   self.restartWebrtcConns()
        // });
      }
    },
    activeSignalRoom: function (newVal, oldVal) {
      if (oldVal && this.streamsPeerReceiver) {
        this.reloadWebrtcConns()
        // @todo maybe later to do auto restart stream on change signal url or signal room
        // let pr1 = self.streamsPeerReceiver.onRoomChangeCommon(newVal, oldVal)
        // Promise.all([pr1]).then(() => {
        //   //self.runAllStreams()
        //   self.restartWebrtcConns()
        // });
      }
    },
    currentCommonRoom: function () {
      if (this.streamsPeerReceiver) {
        this.reloadWebrtcConns()
      }
    },
  },

  methods: {
    createSignalConnection() {
      let self = this
      return new Promise((resolve) => {
        if (self.streamsPeerReceiver) {
          resolve()
        } else {
          setTimeout(() => {
            // initiate WebRTC receiver
            let receiverProps = {
              name: "streams-list-preview",
              signalUrl: self.streamsActiveSignalServerUrl,
              room: self.streamsSignalRoom,
              refComponent: self,
            }
            let receiver = new WebrtcReceiverStreams(receiverProps)
            self.$store
              .dispatch("setStreamsPeerReceiver", receiver)
              .then(() => {
                self.streamsPeerReceiver
                  .getOrCreateConnection()
                  .then(function () {
                    self.streamsPeerReceiver.on(
                      "streams-history-active",
                      (result) => {
                        console.log("streams-history-active", result)
                        self.$store
                          .dispatch("streamsSaveActiveList", result)
                          .then(() => {
                            self.receivedActiveData = true
                          })
                      }
                    )
                    self.streamsPeerReceiver.on(
                      "streams-history-inactive",
                      (result) => {
                        console.log("streams-history-inactive", result)
                        self.$store
                          .dispatch("streamsSaveInactiveList", result)
                          .then(() => {
                            self.receivedInactiveData = true
                          })
                      }
                    )
                    self.streamsPeerReceiver.on(
                      "streams-history-updated",
                      () => {
                        console.log("streams-history-updated")
                        self.refreshTablesData()
                      }
                    )
                  })
                  .catch(() => {})
                self.streamsPeerReceiver.on("connect-error", () => {
                  console.log("connect-error")
                  self.errorSignalConnection = true
                  resolve()
                })
                self.streamsPeerReceiver.on("connect", () => {
                  resolve()
                })
              })
          }, 200)
        }
      })
    },
    reloadWebrtcConns() {
      let self = this
      let prmsResetStreamsActiveList = self.$store.dispatch(
        "resetStreamsActiveList"
      )
      let prmsResetStreamsInactiveList = self.$store.dispatch(
        "resetStreamsInactiveList"
      )
      self.errorSignalConnection = false
      self.$store.dispatch("saveLoaderAction", true)
      self.$store.commit("RESET_LIST_MEMBERS_WS_CONN")
      let remoteClose = self.streamsPeerReceiver.disconnect()

      Promise.all([
        prmsResetStreamsActiveList,
        prmsResetStreamsInactiveList,
        remoteClose,
      ]).then(() => {
        self.$store.dispatch("setStreamsPeerReceiver", null).then(() => {
          self.createSignalConnection().then(() => {
            self.$store.dispatch("saveLoaderAction", false)
            self.refreshTablesData()
          })
        })
      })
    },
    openPreviewStreamDialog(item) {
      let self = this
      self.$store
        .dispatch("setStreamActiveItem", _.cloneDeep(item))
        .then(() => {
          self.$store.dispatch("saveDialogPreviewStream", true)
        })
    },
    openStreamHistoryDialog(item) {
      let self = this
      self.$store
        .dispatch("setStreamActiveItem", _.cloneDeep(item))
        .then(() => {
          self.$store.dispatch("saveDialogPreviewStreamHistory", true)
        })
    },
    getRequestParams(params = {}) {
      return { ...{ room: this.streamsSignalRoom }, ...params }
    },
    refreshTablesData() {
      let self = this
      if (
        this.$route.name === "engine-streams-list-active" &&
        self.$refs.refSActive
      )
        self.$refs.refSActive.getStreamsData()
      if (
        this.$route.name === "engine-streams-list-history" &&
        self.$refs.refSInactive
      )
        self.$refs.refSInactive.getStreamsData()
    },
  },
  computed: {
    ...mapGetters({
      streamsPeerReceiver: "streamsPeerReceiver",
      streamsActiveCompany: "streamsActiveCompany",
      signalRoomMembersSenders: "signalRoomMembersSenders",
      getDefaultSignalServer: "getDefaultSignalServer",
      streamsActiveSignalServerUrl: "streamsActiveSignalServerUrl",
      getUserGroupsList: "getUserGroupsList",
      streamsSignalRoom: "streamsSignalRoom",
      streamsCommonRoom: "streamsCommonRoom",
      streamsActive: "streamsActive",
      streamsActiveCount: "streamsActiveCount",
      streamsInactive: "streamsInactive",
      streamsInactiveCount: "streamsInactiveCount",
    }),

    currentStreamsCompany: {
      get() {
        return this.$store.getters.streamsActiveCompany
      },
      set(val) {
        this.$store.dispatch("streamsSaveActiveCompany", val)
      },
    },

    currentSignalKey: {
      get() {
        return this.$store.getters.streamsActiveSignalKey
      },
      set(val) {
        this.$store.dispatch("streamsSaveActiveSignal", val)
      },
    },

    currentCommonRoom: {
      get() {
        return this.$store.getters.streamsCommonRoom
      },
      set(val) {
        this.$store.dispatch("streamsSaveCommonRoom", val)
      },
    },

    signalUrlHint() {
      return `Url: ${this.streamsActiveSignalServerUrl}`
    },

    signalRoomHint() {
      return `Room: ${this.streamsSignalRoom}`
    },
  },
}
