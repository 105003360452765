<template>
  <div data-testid="container-active-streams">
    <StreamsTable
      ref="refSActive"
      :streams="streamsActive"
      :count="streamsActiveCount"
      :errorSignalConnection="errorSignalConnection"
      :search.sync="search"
      :inActiveTab="true"
      @open-preview-stream-dialog="openPreviewStreamDialog"
      @open-stream-history-dialog="openStreamHistoryDialog"
      @get-streams-history-list="getActiveStreams"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import StreamsTable from "./StreamsTable"
import StreamCommonMixin from "@/mixins/streams/common"

export default {
  name: "streams-list-active",

  components: {
    StreamsTable,
  },

  mixins: [StreamCommonMixin],

  methods: {
    getActiveStreams(params = {}) {
      let self = this
      self.createSignalConnection().then(() => {
        self.streamsPeerReceiver.getActiveStreamsHistoryList(
          self.getRequestParams(params)
        )
      })
    },
  },

  computed: {
    ...mapGetters({
      streamsActive: "streamsActive",
      streamsActiveCount: "streamsActiveCount",
      streamsPeerReceiver: "streamsPeerReceiver",
    }),
  },
}
</script>
