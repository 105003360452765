/* eslint-disable */
import io from "socket.io-client"
import _ from "lodash"
import PeerConnection from "rtcpeerconnection"
import {
  WEBRTC_CONNECTION_TYPE_SENDER,
  WEBRTC_CONNECTION_TYPE_RECEIVER,
} from "./constants"
import EventEmitter from "events"
import store from "@/store"
import * as VueDeepSet from "vue-deepset"
/* eslint-disable-next-line */
import adapter from "webrtc-adapter"
import UserService from "@/common/api/user.service"
import { getTURNCredentials } from "@/common/webrtc/helpers"
import { emptyHtmlElm } from "@/common/general.service"
import Sdp from "./sdp"

const SENDER_NICKNAME_POSTFIX = "Sender"
const RECEIVER_NICKNAME_POSTFIX = "Preview"
const UNKNOWN_NICKNAME_POSTFIX = "Unknown"

export default class WebrtcCommon extends EventEmitter {
  connection = null
  peer = null
  nickName = ""
  localStream = null
  screen_capture = {
    enableStartCapture: true,
    enableStopCapture: false,
    enableDownloadRecording: false,
    stream: null,
    chunks: [],
    mediaRecorder: null,
    status: "Inactive",
    recording: null,
  }
  playerPlaying = false
  type = null
  hasAddTrack = false
  initialisedPreviewReconnect = false
  props = {
    name: "",
    signalUrl: "http://localhost:8888",
    room: null,
    turn: null,
    forceTurn: false,
    userToken: null,
    bandwidth: null,
    refComponent: null,
    maxAverageBitrate: null,
    useMulticastServer: false,
    multicastType: "",
    multicastServer: "", // "https://j01-eu.arhtengine.com:443" , ws://janus.redox-dev.com:8188
    bestturn: "",
  }
  janusAnswer = null
  numberFailsReqTime = 0

  constructor(optionsReceived) {
    super()
    this.props = { ...this.props, ...optionsReceived }
    this.handleTrackEvent = this.handleTrackEvent.bind(this)
  }

  get peers() {
    return store.getters.getWebrtcPeers
  }

  get localPeers() {
    let localPeers = []
    for (var key in this.peers) {
      if (this.peers[key]["local"]) {
        localPeers.push(this.peers[key]["local"])
      }
    }
    return localPeers
  }

  get remoteStream() {
    return store.getters.getRemoteStreamStore
  }

  set remoteStream(newValue) {
    if (!newValue) {
      store.dispatch("removeRemoteStream")
    }
  }

  // megabits to bits bandwidth
  get bandwidthBits() {
    let bandwidth = this.props.bandwidth
    if (!bandwidth) {
      return null
    }
    return parseFloat(bandwidth) * 1000 * 1000
  }

  // megabits to kilobits bandwidth
  get bandwidthKilobits() {
    let bandwidth = this.props.bandwidth
    if (!bandwidth) {
      return null
    }
    return parseFloat(bandwidth) * 1000
  }

  // megabits to kilobits max bandwidth
  get maxBandwidthKilobits() {
    if (!this.props.maxBandwidth) {
      return null
    }
    return parseFloat(this.props.maxBandwidth) * 1000
  }

  // kilobits to bits maxAverageBitrate
  get maxAverageBitrateBits() {
    let maxAverageBitrate = this.props.maxAverageBitrate
    if (!maxAverageBitrate) {
      return null
    }
    return parseFloat(maxAverageBitrate) * 1000
  }

  get tokenVal() {
    return this.userToken ? this.userToken : UserService.getToken()
  }

  get useJanus() {
    return store.getters.useJanus
  }

  initialize() {
    this.getOrCreateConnection()
  }

  // generate nickname
  getNickName() {
    let genNickName = ""
    let deviceName = "Web"
    let nameAppend =
      this.props.name === ""
        ? `User${store.getters.currentUser._key}`
        : `${this.props.name}`
    if (this.type == WEBRTC_CONNECTION_TYPE_SENDER) {
      deviceName = deviceName + SENDER_NICKNAME_POSTFIX
    } else if (this.type == WEBRTC_CONNECTION_TYPE_RECEIVER) {
      deviceName = deviceName + RECEIVER_NICKNAME_POSTFIX
    } else {
      deviceName = deviceName + UNKNOWN_NICKNAME_POSTFIX
    }
    if (this.useJanus) {
      // For Janus we must generate special nickname
      genNickName = deviceName + nameAppend
      let sLength = deviceName.length
      sLength = sLength < 10 ? "0" + sLength : sLength
      genNickName = `Janus${sLength}${genNickName}`
    } else {
      genNickName = deviceName + ":" + nameAppend
    }
    this.nickName = genNickName
    return this.nickName
  }

  // get nickname setted on signal server
  async getSignalNickname() {
    let conn = await this.getOrCreateConnection()
    return conn.nickName || conn.nickname
  }

  getConnInfoData() {
    let self = this
    let nickName = self.getNickName()
    let data = {
      vidBitrate: "5500000",
      vidEncoder: self.getVideoEncoder(),
      audBitrate: "128K",
      audEncoder: "opus",
      mode: self.type === WEBRTC_CONNECTION_TYPE_SENDER ? "sender" : "receiver",
      nickName: nickName,
      nickname: nickName,
      token: self.tokenVal,
      loginHash:
        "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
      multicastServer: self.props.multicastServer,
      multicastType: self.props.multicastType,
      from: `Web: ${self.props.name}`,
      peer: self.type === WEBRTC_CONNECTION_TYPE_SENDER ? nickName : "",
      object: self.type === WEBRTC_CONNECTION_TYPE_SENDER ? nickName : "",
      //"peer": nickName,
      prefix: "webkit",
      roomType: "Video",
      sid: "",
      bestturn: self.props.bestturn, // used by janus for sender (maybe also by receiver)

      AudChannals: "-1",
      Company: "Redox",
      Preset: "atest",
      serial: "B42E99351E05",
      source: "Talent",
      turn_password: "",
      turn_server:
        '[ {"username":"1627490153:arht-turn", "credential":"GxMdxQdoZYjrwJA15aYFPu8X8+U=", "urls":[ "turn:t01-eu.arhtengine.com:443?transport=tcp" ]}, {"username":"1627490153:arht-turn", "credential":"GxMdxQdoZYjrwJA15aYFPu8X8+U=", "urls":[ "turn:t01-eu.arhtengine.com:443?transport=udp" ]} ]',
      turn_user: "",

      // "peerType": "cpp", !!! atention with this field, it influences bandwith limit set on ASP
      // "turn_server": "",
      // "turn_user": "",
      // "turn_password": "",
      // "PeerEx": nickName,
      // "strongId": self.connection.id,
    }
    if (self.useJanus && self.type === WEBRTC_CONNECTION_TYPE_SENDER) {
      data.peerType = "cpp"
      data.peerEx = nickName
      data.peer = nickName
      data.object = nickName
    }
    return data
  }

  /**
   * Request current ASP list from signal server
   *
   * @param {Object} params - params to send on request refresh room clients list
   * @param {boolean} [params.broadcast=true] - if on request clients list, broadcast list to another clients in room or not
   * @returns {Promise<void>}
   */
  async getAspxStreamsList(params = { broadcast: false }) {
    let conn = await this.getOrCreateConnection()
    if (conn && !_.has(conn, "error")) {
      conn.emit("getroommembers", params)
    }
  }

  /**
   * Request current Active Streams history from signal server
   *
   * @param {Object} params - params to send on request refresh room clients list
   * @returns null
   */
  async getActiveStreamsHistoryList(params = {}) {
    let conn = await this.getOrCreateConnection()
    if (conn && !_.has(conn, "error")) {
      conn.emit("1.0.streams.history.active", params)
    }
  }

  /**
   * Request current Inactive Streams history from signal server
   *
   * @param {Object} params - params to send on request refresh room clients list
   * @returns null
   */
  async getInactiveStreamsHistoryList(params = {}) {
    let conn = await this.getOrCreateConnection()
    if (conn && !_.has(conn, "error")) {
      conn.emit("1.0.streams.history.inactive", params)
    }
  }

  async disconnect() {
    let self = this
    let conn = await this.getOrCreateConnection(false)
    return new Promise((resolve, reject) => {
      if (conn) {
        try {
          conn.emit("leave")
          conn.disconnect()
          conn.close()
        } catch (err) {
          console.error(`Error on disconnect ${self.type}`, err)
        }
      }
      self.connection = null
      setTimeout(() => {
        resolve()
      }, 200)
    })
  }

  getOrCreateConnection(forceCreate = true) {
    let self = this
    return new Promise((resolve, reject) => {
      if (self.connection) {
        return resolve(self.connection)
      }
      if (!forceCreate) {
        return resolve(self.connection)
      }

      console.log("self.props.signalUrl", self.props.signalUrl)
      console.log("self.props.room", self.props.room)

      if (self.props.signalUrl) {
        self.connection = io(self.props.signalUrl, {
          forceNew: true,
          "force new connection": true,
          transports: ["websocket"],
        })
        let isFullfiled = false
        if (self.connection) {
          self.connection.on("connect_error", function (err) {
            self.emit("connect-error", err)
            isFullfiled = true
            reject(err)
            return
          })
          self.connection.on("error", function (err) {
            self.emit("connect-error", err)
            isFullfiled = true
            reject(err)
            return
          })
          self.connection.io.on("error", function (err) {
            self.emit("connect-error", err)
            isFullfiled = true
            reject(err)
            return
          })
          self.connection.on("connect", function () {
            self.emit("connect")
            console.log("on connect socket io client", self.type)
            let info = self.getConnInfoData()
            self.connection.emit("setinfo", JSON.stringify(info))
            self.connection.emit("join", self.props.room)

            setTimeout(function () {
              try {
                // console.log('Created conn --' + self.type, self.connection.id);
                self.connection.on("event", function (data) {
                  console.log("event")
                })
                // conn.on('connect', function (conn) {
                //     resolve(self.connection);
                //     console.log('connect');
                // });
                self.connection.addEventListener("disconnect", () => {
                  console.log("on disconnect socket io client")
                  if (!isFullfiled) {
                    reject()
                    return
                  }
                })
                // self.connection.on('disconnect', function () {
                //     //self.$root.$emit('disconnect-signal', params);
                //     //self.$emit('disconnect-signal', );
                //     console.log('on disconnect socket io client');
                // });

                // if component mount multiple times
                // it will create 2 listeners of `message`
                // so remove old listeners
                self.connection.removeAllListeners("message")
                self.connection.on("loginjanusrequest", function () {
                  console.log("loginjanusrequest11111111111")
                  // @todo this was made by Forasoft doesn't understand if is correct???
                  if (self.props.multicastType === "janus") {
                    self
                      .getOrCreatePeerConnection(self.connection.id, "local")
                      .then(() => {
                        self.makeJanusOfferPreview()
                      })
                  }
                })
                self.connection.on("message", function (msg) {
                  console.log("message", msg)

                  switch (msg.type) {
                    case "offer": // Invitation and offer to chat
                      self.handleVideoOfferMsg(msg)
                      break

                    case "answer": // Callee has answered our offer
                      self.handleVideoAnswerMsg(msg)
                      break

                    case "candidate": // A new ICE candidate has been received
                      self.handleNewICECandidateMsg(msg)
                      break

                    case "hang-up": // The other peer has hung up the call
                      self.handleHangUpMsg(msg)
                      break

                    case "endOfCandidates":
                      console.log("endOfCandidates")
                      break
                    case "refresh-members":
                      self.getAspxStreamsList()
                      break
                    case "januslistner":
                      self.handleJanusListenerOffer(msg)
                      break
                    // Unknown message; output to console for debugging.
                    default:
                      self.emit("unknown-received-msg", msg)
                      console.log("Unknown message received:", msg)
                    // self.log_error("Unknown message received:");
                    // self.log_error(msg);
                  }
                })
                self.connection.removeAllListeners("roommembers")
                self.connection.on("roommembers", function (members) {
                  // @todo this must be fixed on signal server do not return token and loginHash fields
                  let filteredMembers = _.omit(members.clients, [
                    "token",
                    "loginHash",
                  ])
                  let membersData = {
                    all: filteredMembers,
                    senders: _.filter(filteredMembers, (v) => v.mode == "sender"), //&& v.id != conn.id
                    receivers: _.filter(
                      filteredMembers,
                      (v) => v.mode == "receiver"
                    ), //&& v.id != conn.id
                  }
                  // console.log('ON--roommembers', membersData);
                  store.commit("SAVE_LIST_MEMBERS_WS_CONN", membersData)
                  self.emit("room-members", membersData)
                })
                self.connection.on(
                  "1.0.streams.history.active",
                  function (streamsData) {
                    self.emit("streams-history-active", streamsData.data)
                  }
                )
                self.connection.on(
                  "1.0.streams.history.inactive",
                  function (streamsData) {
                    self.emit("streams-history-inactive", streamsData.data)
                  }
                )
                self.connection.on("1.0.streams.history.updated", function () {
                  self.emit("streams-history-updated")
                })
                self.getAspxStreamsList()
                //self.connection.emit('getroommembers');
                isFullfiled = true
                resolve(self.connection)
              } catch (errEventsConn) {
                reject("Errors on signal connect events init", errEventsConn)
              }
            }, 200)
          })
        } else {
          reject("error on connect")
        }
      } else {
        reject("there are no signal url")
      }
    })
  }

  makeJanusOfferPreview() {
    let self = this
    self
      .getOrCreatePeerConnection(self.connection.id, "local")
      .then((localPeer) => {
        localPeer.offer(
          { offerToReceiveAudio: true, offerToReceiveVideo: true },
          function (err, offer) {
            if (!err) {
              const msg_send = {
                audBitrate: "128000",
                audEncoder: "opus",
                from: "",
                payload: offer,
                prefix: "webkit",
                roomType: "Video",
                sid: "",
                to: "janus",
                type: "offer",
                vidBitrate: "5500000",
                vidEncoder: "h264",
              }
              self.connection.emit("message", JSON.stringify(msg_send))
              setTimeout(() => {
                localPeer.pc.setLocalDescription(offer).then(() => {
                  // setTimeout(() => {
                  //   console.log('endOfCandidates22222222')
                  //   let msg_send_end = {"from":"","prefix":"webkit","roomType":"Video","sid":"","to":"janus","type":"endOfCandidates"}
                  //   self.connection.emit('message', JSON.stringify(msg_send_end));
                  // }, 4000)
                })
              }, 400)
            }
          }
        )
      })
  }

  refreshSignalInfo() {
    let info = this.getConnInfoData()
    if (this.connection) {
      this.connection.emit("setinfo", JSON.stringify(info))
    }
  }

  // compare old and new nickname if there are differences update it
  async refreshNickname() {
    // let signalNickname = await this.getSignalNickname();
    let generatedNickname = this.getNickName()
    this.connection.emit("nickname", generatedNickname)
  }

  // create peer without to bind sender to receiver (write in self.peers)
  createPlainPeerConnection() {
    let self = this
    return new Promise((resolve, reject) => {
      try {
        // Create an RTCPeerConnection which knows to use our chosen
        // STUN server.
        let configuration = {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }
        let constraints = {
          mandatory: {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true,
          },
        }

        let iceServers = [
          {
            urls: "stun:stun.l.google.com:19302",
          },
        ]
        // console.log('self.props.forceTurn', self.props.forceTurn)
        // console.log('self.props.turn', self.props.turn)

        let iceTransportPolicy = self.props.forceTurn ? "relay" : "all"

        let peerConfigs = {
          configuration: configuration,
          sdpSemantics: "unified-plan",
          iceServers: iceServers,
        }
        let activeTurnData = self.getActiveTurnData(self.props.turn)
        if (activeTurnData) {
          iceServers = [...iceServers, ...activeTurnData]
          peerConfigs = {
            configuration: configuration,
            sdpSemantics: "unified-plan",
            iceServers: iceServers,
            iceTransportPolicy: iceTransportPolicy,
            iceOptions: {
              gatherPolicy: iceTransportPolicy,
            },
          }
        }
        let myPeerConnection = new PeerConnection(peerConfigs, constraints)

        // you can listen for end of candidates (not particularly useful)
        myPeerConnection.on("endOfCandidates", function () {
          // no more ice candidates
        })

        // remote stream added

        try {
          myPeerConnection.pc.ontrack = self.handleTrackEvent
        } catch (err) {
          console.error("Handle self error on pc.ontrack", err)
          try {
            myPeerConnection.pc.onaddstream = self.handleTrackEvent
          } catch (err) {
            console.error("Handle self error on pc.onaddstream", err)
          }
        }

        // remote stream removed
        myPeerConnection.on("removeStream", self.handleRemoveStreamEvent)

        // you can chose to listen for events for
        // offers and answers instead, if you prefer
        myPeerConnection.on("answer", function (err, answer) {
          //console.log('answer evnt----', answer);
        })

        myPeerConnection.on("offer", function (err, offer) {
          //console.log('answer evnt----', offer);
        })

        // on peer connection close
        myPeerConnection.on("close", function () {
          // console.log('on close peer----');
        })

        let negotiating = false
        myPeerConnection.pc.onnegotiationneeded = async (e) => {
          //if (myPeerConnection.pc.signalingState != "stable") return;
          try {
            if (negotiating || myPeerConnection.pc.signalingState != "stable")
              return
            negotiating = true
            /* Your async/await-using code goes here */
          } finally {
            negotiating = false
          }
        }

        myPeerConnection.pc.addEventListener("track", async (event) => {
          setTimeout(() => {
            //console.log('event track-----', event.track, event.streams[0].getTracks())
          }, 1000)
        })

        // myPeerConnection.pc.onconnectionstatechange = function (event) {
        //     switch (myPeerConnection.pc.connectionState) {
        //         case "connected":
        //             // The connection has become fully connected
        //             console.log('onconnectionstatechange--connected');
        //             break;
        //         case "disconnected":
        //             console.log('onconnectionstatechange--disconnected');
        //         case "failed":
        //             console.log('onconnectionstatechange--failed');
        //             // One or more transports has terminated unexpectedly or in an error
        //             break;
        //         case "closed":
        //             console.log('onconnectionstatechange--closed');
        //             // The connection has been closed
        //             break;
        //     }
        // };
        resolve(myPeerConnection)
      } catch (err) {
        reject(err)
        console.error("Error on create RTCPeerConnection")
      }
    })
  }

  /**
   * Get array of turns data
   */
  getActiveTurnData(turnData) {
    let self = this
    if (turnData) {
      let turns = Array.isArray(turnData) ? turnData : [turnData]
      let activeTurnsArray = []
      turns.forEach((turn) => {
        let currTurns = self.getStunTurnData(turn).turnServers
        activeTurnsArray = [...activeTurnsArray, ...currTurns]
      })
      return activeTurnsArray
    }
    return null
  }

  // Create the RTCPeerConnection which knows how to talk to our
  // selected STUN/TURN server and then uses getUserMedia() to find
  // our camera and microphone and add that stream to the connection for
  // use in our video call. Then we configure event handlers to get
  // needed notifications on the call.
  getOrCreatePeerConnection(callerId, type = "local", calleeId) {
    let self = this
    return new Promise((resolve, reject) => {
      // this case is actual if we create peer without bind sender to receiver with self.createPlainPeerConnection()
      // if (self.peer) {
      //   VueDeepSet.vueSet(self.peers, [callerId, type], self.peer);
      // }
      let findedPeer = _.get(self.peers, [callerId, type])
      if (!findedPeer) {
        self.createPlainPeerConnection()
          .then((myPeerConnection) => {
            self
              .initPeersOnBind(myPeerConnection, callerId, type, calleeId)
              .then((peer) => {
                resolve(peer)
              })
          })
          .catch((err) => {
            self.emit("peer-connect-error", err)
          })
      } else {
        self
          .initPeersOnBind(findedPeer, callerId, type, calleeId)
          .then((peer) => {
            resolve(peer)
          })
      }
    })
  }

  initPeersOnBind(myPeerConnection, callerId, type = "local", calleeId) {
    let self = this
    return new Promise((resolve, reject) => {
      // ice candidates
      myPeerConnection.pc.onicecandidate = (cand) => {
        let candStr = cand?.candidate?.candidate
        if (candStr) {
          var msg_send = {
            from: "", //self.connection.id
            payload: {
              "candidate": {
                candidate: cand?.candidate?.candidate,
                sdpMLineIndex: cand?.candidate?.sdpMLineIndex,
                sdpMid: cand?.candidate?.sdpMid,
              }
            },
            prefix: "webkit",
            roomType: "Video",
            sid: "",
            to: self.useJanus ? "janus" : calleeId || callerId,
            type: "candidate",
            token: self.tokenVal,
            loginHash:
              "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
          }
          self.getOrCreateConnection().then((conn) => {
            conn.emit("message", JSON.stringify(msg_send))
          })
        }
      }

      myPeerConnection.on('endOfCandidates', function () {
        // no more ice candidates
      });
      VueDeepSet.vueSet(self.peers, [callerId, type], myPeerConnection)
      resolve(myPeerConnection)
    })
  }

  // close all peers connection for senders and receivers
  closeAllPeerConnections() {
    let self = this
    return new Promise((resolve, reject) => {
      try {
        _.forEach(self.peers, function (val, key) {
          _.forEach(val, function (pr, prKey) {
            if (
              (self.type == WEBRTC_CONNECTION_TYPE_SENDER &&
                prKey == "local") ||
              (self.type == WEBRTC_CONNECTION_TYPE_RECEIVER &&
                prKey == "remote")
            ) {
              // if in remote preview are showed local stream on close we close and preview
              // if (self.$options.name == 'webengine-sender' && prKey == 'local' && !_.isNull(_.get(self.peers, [key, 'local'])) && !_.isNull(_.get(self.peers, [key, 'remote']))) {
              //     self.emit('close-remote-preview');
              // }
              if (!_.isEmpty(pr)) {
                try {
                  pr.onaddstream = null // For older implementations
                } catch (err) {
                }
                pr.ontrack = null // For newer ones
                pr.onremovestream = null
                pr.onnicecandidate = null
                pr.oniceconnectionstatechange = null
                pr.onsignalingstatechange = null
                pr.onicegatheringstatechange = null
                pr.onnotificationneeded = null
                // Close the peer connection
                try {
                  pr.close()
                } catch (err) {
                  console.log("err on close peer", err)
                }
              }
              VueDeepSet.vueSet(self.peers, [key, prKey], null)
            }
          })
          if (
            !self.peers[key] ||
            (!_.get(self.peers, [key, "local"]) &&
              !_.get(self.peers, [key, "remote"]))
          ) {
            delete self.peers[key]
          }
        })
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  // Called by the WebRTC layer when events occur on the media tracks
  // on our WebRTC call. This includes when streams are added to and
  // removed from the call.
  //
  // track events include the following fields:
  //
  // RTCRtpReceiver       receiver
  // MediaStreamTrack     track
  // MediaStream[]        streams
  // RTCRtpTransceiver    transceiver

  handleTrackEvent(event) {
    let self = this
    let stream = null
    console.log("handleTrackEvent", event, self.type)
    if (event.track && self.useJanus) {
      // @todo hack in case wen we receive Janus stream there is problem with doublicates of audio stream
      stream = new MediaStream()
      stream.addTrack(event.track)
    } else if (event instanceof RTCTrackEvent) {
      stream = event.streams[0]
    } else if (event instanceof MediaStreamEvent) {
      stream = event.stream
    }
    setTimeout(() => {
      if (stream) {
        // @todo limit of audio channels work only in firefox
        // let tracks = stream.getTracks()
        // let flagMuted = tracks.length > 0 ? tracks[0].muted : true
        // if (!flagMuted) {
        if (self.type == WEBRTC_CONNECTION_TYPE_SENDER) {
          // handle local stream, maybe will not be this event never
        } else {
          let nickPreviewedStream = _.get(self.previewedConn, "nickName")
          if (
            !nickPreviewedStream ||
            nickPreviewedStream.includes("_WebSender")
          ) {
            // when we receive local stream we do not split audio in multiple channels (maybe must be fixed in future)
            self.addVideoStream(stream, "remote")
            store
              .dispatch("saveIsPreviewRemoteStreamStarted", true)
              .then(() => {
                self.removeReconnectPreview()
              })
          } else {
            // when we receive remote stream split audio in multiple channels
            store.dispatch("saveRemoteStream", stream).then(() => {
              // let aElm = document.querySelector('audio#received_audio');
              // aElm.srcObject = streamm.getAudioTracks();
              self.addVideoStream(self.remoteStream, "remote")
              store
                .dispatch("saveIsPreviewRemoteStreamStarted", true)
                .then(() => {
                  self.removeReconnectPreview()
                })
            })
          }
        }
        // }
      }
    }, 1000)
  }

  // An event handler which is called when the remote end of the connection
  // removes its stream. We consider this the same as hanging up the call.
  // It could just as well be treated as a "mute".
  //
  // Note that currently, the spec is hazy on exactly when this and other
  // "connection failure" scenarios should occur, so sometimes they simply
  // don't happen.

  handleRemoveStreamEvent(event) {
    //this.closeVideoCall();
  }

  // Handle the "hang-up" message, which is sent if the other peer
  // has hung up the call or otherwise disconnected.

  handleHangUpMsg(msg) {
    // some callback
  }

  getStreamContainer(type) {
    let containerId =
      (!_.isUndefined(type) && type == "local") ||
      (_.isUndefined(type) && this.type == WEBRTC_CONNECTION_TYPE_SENDER)
        ? "local_video_container"
        : "received_video_container"
    return document.getElementById(`${containerId}`)
  }

  getStreamElmId(type) {
    if (
      (!_.isUndefined(type) && type == "local") ||
      (_.isUndefined(type) && this.type == WEBRTC_CONNECTION_TYPE_SENDER)
    ) {
      return this.mediaConstraints &&
      !this.mediaConstraints.video &&
      this.mediaConstraints.audio
        ? "local_audio"
        : "local_video"
    }
    return "received_video"
  }

  getStreamElm(type) {
    let elmId = this.getStreamElmId(type)
    if (
      this.mediaConstraints &&
      !this.mediaConstraints.video &&
      this.mediaConstraints.audio
    ) {
      return document.querySelector("audio#" + elmId)
    }
    return document.querySelector("video#" + elmId)
  }

  addVideoStream(stream, type) {
    let self = this
    let findedVideoElm = this.getStreamElm(type)
    // @todo for speed test we did mute player for receiver, possible to be error when not muted
    // also we mute sender stream for player in webengine (when we use webcamera)
    let muteAudioInPlayer =
      this.getCallerName() === "WebrtcSenderWebengine" ||
      this.getCallerName() === "WebrtcReceiverSpeedTest"
    if (findedVideoElm) {
      if (this.type == WEBRTC_CONNECTION_TYPE_SENDER) {
        findedVideoElm.muted = true
      }
      if (!self.playerPlaying && stream && stream instanceof MediaStream) {
        findedVideoElm.setAttribute("controls", true)
        // @todo in speed test possible to be error when not muted
        findedVideoElm.setAttribute("muted", true)
        self.runVideoElm(findedVideoElm, stream, muteAudioInPlayer)
      }
    } else {
      let container = this.getStreamContainer(type)
      emptyHtmlElm(container)
      let videoId = this.getStreamElmId(type)
      const video = document.createElement("video")
      video.setAttribute("muted", true)
      video.setAttribute("crossOrigin", "anonymous")
      video.setAttribute("playsinline", true)
      video.setAttribute("controls", true)
      video.setAttribute("id", videoId)
      if (container) {
        let aV = container.appendChild(video)
        self.runVideoElm(aV, stream, muteAudioInPlayer)
      } else {
        console.log("Error on addVideoStream, stream container is undefined")
      }
    }
  }

  runVideoElm(elm, stream, muteAudioInPlayer = false) {
    let self = this
    if (stream && stream instanceof MediaStream) {
      if ("srcObject" in elm) {
        console.log("elm.srcObject", elm.srcObject)
        if (elm.srcObject) {
          elm.pause()
        }
        elm.srcObject = stream
      } else {
        // Avoid using this in new browsers, as it is going away.
        elm.src = URL.createObjectURL(stream)
      }
      elm.muted = muteAudioInPlayer
      setTimeout(() => {
        let playPromise = elm.play()
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // play started with success
            self.emit("start-play-state", true)
          }).catch(() => {
            // play started without success
            self.emit("start-play-state", false)
          })
        }
      }, 500)

      elm.onplaying = function () {
        self.playerPlaying = true
        elm.muted = muteAudioInPlayer
      }
      elm.onpause = function () {
        self.playerPlaying = false
      }
    }
  }

  // get video encoder, at this moment from web work:
  // 1) for send local stream - h264
  // 2) for receive remote stream from asp vp8
  getVideoEncoder() {
    // return this.type == WEBRTC_CONNECTION_TYPE_SENDER ? 'h264' : 'vp8'
    return store.getters.getSenderVideoCodec
    //return this.type == WEBRTC_CONNECTION_TYPE_SENDER ? store.getters.getSenderVideoCodec : 'h264'
  }

  // On request to receive local Janus stream
  handleJanusListenerOffer(msg) {
    let self = this
    let callerId = msg.from
    console.log(
      "handleJanusListenerOffer--self.janusAnswer",
      self.janusAnswer,
      msg
    )
    if (self.janusAnswer) {
      setTimeout(() => {
        var msg_send = {
          audBitrate: "128K",
          audEncoder: "opus",
          vidBitrate: "5500000",
          vidEncoder: self.getVideoEncoder(),
          from: "", //self.connection.id
          payload: self.janusAnswer,
          to: callerId,
          type: "answer",
          token: self.tokenVal,
          loginHash:
            "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
          prefix: "webkit",
          roomType: "Video",
          sid: "",
          multicastServer: self.props.multicastServer,
          multicastType: self.props.multicastType,

          // "PeerEx": nickName,
          // "peer": nickName,
        }
        console.log(
          "+++Sender " +
          self.connection.id +
          " send answer for januslistner-----",
          msg_send
        )
        self.connection.emit("message", JSON.stringify(msg_send))
      }, 500)
    }
  }

  // Accept an offer to video chat. We configure our local settings,
  // create our RTCPeerConnection, get and attach our local camera
  // stream, then create and send an answer to the caller.

  handleVideoOfferMsg(msg) {
    let self = this
    let callerId = msg.from
    let caleeId = msg.to
    let peers = self.peers
    let localPeer = _.get(peers, [callerId, "local"])
    let remotePeer = _.get(peers, [callerId, "remote"])

    self.getOrCreatePeerConnection(callerId, "local").then((localPeer) => {
      localPeer.pc
        .setRemoteDescription(new RTCSessionDescription(msg.payload))
        .then(() => {
          // you can call answer with contstraints
          localPeer.pc.createAnswer().then(function (answer) {
            console.log("handleVideoOfferMsg--answer", JSON.stringify(answer))

            // self.myPeerConnection.pc.setLocalDescription(answer);
            // if (self.bandwidthKilobits) {
            //   // // @todo maybe later set bandwidth for audio
            //   // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidthKilobits), "audio", 100);
            //   answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.bandwidthKilobits);
            // }

            localPeer.pc.setLocalDescription(answer).then(() => {
              if (self.type == WEBRTC_CONNECTION_TYPE_SENDER) {
                self.onBandwidthChange(self.props.bandwidth)
              }
              if (self.bandwidthKilobits) {
                // // @todo this is for refresh bitrate limit in answer
                // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidthKilobits), "audio", 100);
                //answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.bandwidthKilobits);
                answer.sdp = Sdp.updateBandwidthRestriction(
                  answer.sdp,
                  self.bandwidthKilobits
                )
              }
              if (self.maxAverageBitrateBits) {
                answer.sdp = Sdp.addStereoMaxAverageBitrate(
                  answer.sdp,
                  self.maxAverageBitrateBits
                )
              }
              //answer.sdp = Sdp.removeBandwidthRestriction(answer.sdp);
              let nickName = self.getNickName()
              var msg_send = {
                audBitrate: "128K",
                audEncoder: "opus",
                vidBitrate: "5500000",
                vidEncoder: self.getVideoEncoder(),
                from: "", //self.connection.id
                payload: answer,
                to: callerId,
                type: "answer",
                token: self.tokenVal,
                loginHash:
                  "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
                prefix: "webkit",
                roomType: "Video",
                sid: "",
                multicastServer: self.props.multicastServer,
                multicastType: self.props.multicastType,

                // "PeerEx": nickName,
                // "peer": nickName,
              }
              console.log(
                "+++Sender " + self.connection.id + " send answer -----",
                msg_send
              )
              self.connection.emit("message", JSON.stringify(msg_send))
              //});
            })
          })
        })
        .catch((err) => {
          console.error("Error on setRemoteDescription", err)
        })
    })

    // if (localPeer && remotePeer && remotePeer.conn !== caleeId) {
    //     // for show stats we connect this way
    //     remotePeer.peer.pc.createAnswer().then(function (answer) {
    //             remotePeer.peer.pc.setLocalDescription(answer).then(
    //                 () => {
    //                     let p;
    //                     if (self.maxBandwidth != "unlimited") {
    //                         // // @todo maybe later set bandwidth for audio
    //                         // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidth), "audio", 100);
    //                         answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidth);
    //                         p = localPeer.peer.pc.setRemoteDescription({
    //                             type: answer.type,
    //                             sdp: answer.sdp
    //                         });
    //                     } else {
    //                         p = localPeer.peer.pc.setRemoteDescription(answer);
    //                     }
    //                     //answer.sdp = Sdp.setMediaBitrates(answer.sdp);
    //                     //p = localPeer.pc.setRemoteDescription(answer);
    //                     p.then(() => {
    //                         var msg_send = {
    //                             "audBitrate": "128000",
    //                             "audEncoder": "opus",
    //                             "from": self.connection.id, //"", //self.connection.id
    //                             "payload": answer,
    //                             "prefix": "webkit",
    //                             "roomType": "Video",
    //                             "sid": "",
    //                             "to": callerId,
    //                             "type": "answer",
    //                             "vidBitrate": "5500000",
    //                             "vidEncoder": "h264",
    //                             "multicastType": null,
    //                         };
    //
    //                         console.log('+++Sender ' + self.connection.id + ' send answer -----', msg_send);
    //                         self.connection.emit('message', JSON.stringify(msg_send));
    //                     }); //, self.onSetSessionDescriptionError
    //
    //                 });
    //         });
    // } else {
    //     self.getOrCreatePeerConnection(callerId, 'local').then((localPeer) => {
    //         let MY_CONSTRAINTS = {
    //             mandatory: {
    //                 OfferToReceiveAudio: true,
    //                 OfferToReceiveVideo: true
    //             }
    //         };
    //
    //         localPeer.peer.pc.setRemoteDescription(msg.payload).then(() => {
    //             // you can call answer with contstraints
    //             localPeer.peer.answer(MY_CONSTRAINTS, function (err, answer) {
    //                 if (!err) {
    //                     // self.myPeerConnection.pc.setLocalDescription(answer);
    //                     if (self.maxBandwidth != "unlimited") {
    //                         // // @todo maybe later set bandwidth for audio
    //                         // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidth), "audio", 100);
    //                         answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidth);
    //                     }
    //                     var msg_send = {
    //                         "audBitrate": "128000",
    //                         "audEncoder": "opus",
    //                         "from": "", //self.connection.id
    //                         "payload": answer,
    //                         "prefix": "webkit",
    //                         "roomType": "Video",
    //                         "sid": "",
    //                         "to": callerId,
    //                         "type": "answer",
    //                         "vidBitrate": "5500000",
    //                         "vidEncoder": "h264",
    //                         "multicastType": null,
    //                     };
    //                     console.log('+++Sender ' + self.connection.id + ' send answer -----', msg_send);
    //                     self.connection.emit('message', JSON.stringify(msg_send));
    //                 }
    //             });
    //         });
    //     });
    // }
  }

  // Responds to the "video-answer" message sent to the caller
  // once the callee has decided to accept our request to talk.
  handleVideoAnswerMsg(msg) {
    let self = this
    //self.log("Call recipient has accepted our call");
    // Configure the remote description, which is the SDP payload
    // in our "video-answer" message.
    console.log("handleVideoAnswerMsg ---", msg)
    // console.log('handleVideoAnswerMsg ---', msg.payload.sdp);
    if (msg.from === "janus") {
      self.janusAnswer = msg.payload
      console.log("janusAnswer111111111111111111111111111", self.janusAnswer)
    } else {
      self.getPeerByMsg(msg).then((peer) => {
        peer.handleAnswer(msg.payload)
      })
    }
  }

  // A new ICE candidate has been received from the other peer. Call
  // RTCPeerConnection.addIceCandidate() to send it along to the
  // local ICE framework.

  handleNewICECandidateMsg(msg) {
    let self = this
    // self.log("Adding received ICE candidate: " + JSON.stringify(msg.payload));
    self.getPeerByMsg(msg).then((peer) => {
      peer.processIce(msg.payload)
    })
  }

  getStunTurnData(dataTurn) {
    let self = this
    let stunServers = null
    let turnServers = null
    if (dataTurn) {
      var urlName = dataTurn.data["hostname"] + ":" + dataTurn.data["port"]
      // var authorizationUsers = dataTurn['autorization'];
      var authorizationUsers = [
        {
          login: "arht-turn",
          password: "4d535ab1-8eg3-4558-8083-af0aa0283444",
        },
      ]
      turnServers = []
      _.each(authorizationUsers, function (user) {
        var username = user["login"]
        var pass = user["password"]

        // Coturn TURN server
        var turnUrls = [
          "turn:" + urlName + "?transport=tcp",
          "turn:" + urlName + "?transport=udp",
        ]
        var turnCredentials = getTURNCredentials(username, pass)
        turnUrls.forEach(function (server) {
          turnServers.push({
            username: turnCredentials["username"],
            credential: turnCredentials["password"],
            urls: [server],
          })
        })
      })
      stunServers = [
        {
          url: "stun:" + urlName,
        },
      ]
    }
    //console.log('turnServers', JSON.stringify(turnServers));
    return {
      stunServers: stunServers,
      turnServers: turnServers,
    }
  }

  onSetSessionDescriptionError(error) {
    console.log("Failed to set session description: " + error.toString())
  }

  onCreateSessionDescriptionError(error) {
    console.log("Failed to create session description: " + error.toString())
  }

  // callback when room change
  onSignalUrlChangeCommon(newVal, oldVal) {
    let self = this
    return new Promise((resolve) => {
      self.props.signalUrl = newVal
      resolve()
    })
  }

  // callback when room change
  onRoomChangeCommon(newVal, oldVal) {
    let self = this
    return new Promise((resolve) => {
      setTimeout(function () {
        if (newVal && self.props.signalUrl) {
          self.props.room = newVal
          self.getOrCreateConnection().then((conn) => {
            conn.emit("leave", oldVal)
            conn.emit("join", newVal)
            self.stopAllStreams().then(() => {
              resolve()
            })
          })
        }
      }, 50)
    })
  }

  log(text) {
    var time = new Date()

    console.log("[" + time.toLocaleTimeString() + "] " + text)
  }

  // Output an error message to console.
  log_error(text) {
    var time = new Date()

    console.error("[" + time.toLocaleTimeString() + "] " + text)
  }

  /**
   * Get info about WebRTC selected peer candidate
   */
  getPeerActiveCandidate() {
    let self = this
    let type =
      self.getCallerName() === "WebrtcReceiverSpeedTest" ? "remote" : "local"
    self.getOrCreateConnection().then((conn) => {
      self.getOrCreatePeerConnection(conn.id, type).then((pConn) => {
        let peerConnection = pConn.pc
        setTimeout(() => {
          peerConnection.getReceivers().map((mt) => {
            const kindOfTrack = mt.track?.kind
            if (mt.transport) {
              const iceTransport = mt.transport?.iceTransport
              const logSelectedCandidate = (e) => {
                const selectedCandidatePair = (iceTransport && typeof iceTransport.getSelectedCandidatePair === "function")
                  ? iceTransport.getSelectedCandidatePair()
                  : null
                self.emit("candidate-pair-info", {
                  kind: kindOfTrack,
                  pair: selectedCandidatePair,
                })
                console.log(
                  `peerConnection-- ${
                    kindOfTrack || "unknown"
                  } SENDER CANDIDATE PAIR`,
                  selectedCandidatePair
                )
              }
              if (iceTransport)
                iceTransport.onselectedcandidatepairchange =
                  logSelectedCandidate
              logSelectedCandidate()
            } else {
              // retry at some time later
              console.log(`peerConnection-- retry at some time later`)
            }
          })
        }, 2500)
      })
    })
  }
}
