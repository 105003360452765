<template>
  <DataTableExtended
    ref="refTableStreams"
    :search="search"
    :headers="headerTab"
    :items="streams"
    item-key="_id"
    class="elevation-0 border"
    show-expand
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    v-on:init-table-data="getStreamsData"
    :server-items-length="count"
    :no-data-text="
      errorSignalConnection
        ? '! The connection to the signal server could not be established'
        : 'No data available'
    "
    :class="{ 'error-signal-conn': errorSignalConnection }"
  >
    <!--    <template v-slot:no-data>-->
    <!--      <template v-if="errorSignalConnection">-->
    <!--        <v-alert-->
    <!--          :text="true"-->
    <!--          :outlined="true"-->
    <!--          dense-->
    <!--          type="error"-->
    <!--          border="left"-->
    <!--          icon="mdi-alert-circle-outline"-->
    <!--          class="ma-0"-->
    <!--        >-->
    <!--          The connection to the signal server could not be established-->
    <!--        </v-alert>-->
    <!--      </template>-->
    <!--      <template v-else>-->
    <!--        No data ss available-->
    <!--      </template>-->
    <!--    </template>-->

    <template v-slot:top> </template>

    <template v-slot:[`item.name`]="{ item }">
      <div>
        {{ item.nickName }}
      </div>
    </template>
    <template v-slot:[`item.id`]="{ item }">
      <div>
        {{ item.id }}
      </div>
    </template>
    <template v-slot:[`item.from`]="{ item }">
      <div>
        {{ item.from }}
      </div>
    </template>
    <template v-slot:[`item.startedAt`]="{ item }">
      <div>
        {{ lastHistoryStartedAt(item) | dateTimeFormat }}
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        dark
        :color="Pallette.actionButtons.bigPlay"
        class="ma-1"
        @click="openPreviewStreamDialog(item)"
        v-if="inActiveTab"
      >
        Preview
        <v-icon dark right>mdi-view-stream</v-icon>
      </v-btn>

      <v-btn
        :color="Pallette.actionButtons.play"
        class="ma-1"
        @click="openStreamHistoryDialog(item)"
      >
        History
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <!-- Expantion Item START-->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="px-0">
        <v-simple-table class="py-2">
          <template v-slot:default>
            <tbody v-if="item.receivers && item.receivers.length">
              <tr v-for="receiver of item.receivers" :key="receiver._id">
                <td style="width: 56px">&nbsp;</td>
                <td style="align: center; width: 20%">
                  {{ receiver.nickName }}
                </td>
                <td style="width: 20%">{{ receiver.from }}</td>
                <td style="width: 20%">
                  {{ lastHistoryStartedAt(item) | dateTimeFormat }}
                </td>
                <td style="text-align: right">
                  <v-btn
                    :color="Pallette.actionButtons.play"
                    class="ma-1"
                    @click="openStreamHistoryDialog(receiver)"
                  >
                    History
                    <v-icon>mdi-history</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr
                :key="'key-no-receivers' + item._id"
                v-if="!item.receivers || !item.receivers.length"
              >
                <td style="width: 56px">&nbsp;</td>
                <td colspan="12" style="align: center">No receivers.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
    <!-- Expantion Item End-->
  </DataTableExtended>
</template>

<script>
import { mapGetters } from "vuex"
import DataTableExtended from "@/components/table/DataTableExtended"

export default {
  name: "streams-table",

  props: {
    streams: {
      type: Array,
      default: function () {
        return []
      },
    },
    count: {
      type: Number,
      default: 0,
    },
    errorSignalConnection: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
    inActiveTab: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DataTableExtended,
  },

  data: () => ({
    expanded: [],
    singleExpand: true,
    headers: [
      { text: "", value: "data-table-expand", sortable: false },
      { text: "Name", value: "nickName", width: "20%", sortable: true },
      { text: "Location", value: "from", width: "20%", sortable: true },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
  }),

  methods: {
    getStreamsData() {
      let self = this
      let getParams = self.$refs.refTableStreams.getTableServerParams()
      self.$emit("get-streams-history-list", getParams)
    },
    openPreviewStreamDialog(item) {
      this.$emit("open-preview-stream-dialog", item)
    },
    openStreamHistoryDialog(item) {
      this.$emit("open-stream-history-dialog", item)
    },
    lastHistoryStartedAt(item) {
      return item.histories.slice(-1)[0].startedAt
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
    }),

    headerTab() {
      let headersTab = this.headers
      if (this.inActiveTab) {
        headersTab.splice(3, 0, {
          text: "Started",
          value: "startedAt",
          width: "20%",
          sortable: false,
        })
      }
      return headersTab
    },
  },
}
</script>

<style lang="scss">
.error-signal-conn .v-data-table__empty-wrapper {
  color: #f44336 !important;
  font-weight: bold !important;
}
</style>
